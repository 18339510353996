<template>
  <div>
    <div v-for="game in games" :key="game.ID" class="py-2 tour-link">
      <div class="d-flex">
        <div class="flex-shrink-1 align-self-center text-left game-time-block">
          <div class="font-weight-normal time-start-game text-white">
            {{ game.time }}
          </div>
          <div
            class="small game-online"
            v-bind:class="[
              game.state === 'live'
                ? dataSport.textClass
                : 'text-white-50 state-small'
            ]"
          >
            {{ game.state }}
          </div>
        </div>
        <div class="pl-2 w-100">
          <div class="d-flex justify-content-center align-items-center">
            <div class="text-right w-100">
              <div
                class="font-weight-normal team-font-size small text-white"
                v-text="
                  $i18n.locale === 'ru'
                    ? game.team_side_1_title
                    : game.team_side_1_title_eng
                "
              />
            </div>
            <div class="px-3 game-score text-center">
              <div v-if="game.score_side1 > game.score_side2">
                <div class="text-nowrap">
	                <span v-if="game.side1_id === game.team_overtime_win" :class="dataSport.textClass">OT&nbsp;</span>
                  <span :class="dataSport.textClass">{{ game.score_side1 }}</span>
                  <span class="separator text-white">-</span>
                  <span class="text-white">{{ game.score_side2 }}</span>
                </div>
              </div>
              <div v-else-if="game.score_side1 < game.score_side2">
                <div class="text-nowrap">
                  <span class="text-white">{{ game.score_side1 }}</span>
                  <span class="separator text-white">-</span>
                  <span :class="dataSport.textClass">{{ game.score_side2 }}</span>
	                <span v-if="game.side2_id === game.team_overtime_win" :class="dataSport.textClass">&nbsp;OT</span>
                </div>
              </div>
              <div
                v-else-if="
                  game.score_side1 === game.score_side2 &&
                    game.state !== 'upcoming'
                "
              >
                <div class="text-nowrap">
                  <span class="text-white">{{ game.score_side1 }}</span>
                  <span class="separator text-white">-</span>
                  <span class="text-white">{{ game.score_side2 }}</span>
                </div>
              </div>
              <div v-else>
                <b class="separator text-white">-</b>
              </div>
            </div>
            <div class="text-left w-100">
              <div
                class="font-weight-normal team-font-size small text-white"
                v-text="
                  $i18n.locale === 'ru'
                    ? game.team_side_2_title
                    : game.team_side_2_title_eng
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StageGames",
  props: ["games", "dataSport"]
};
</script>

<style scoped>
.separator {
  font-size: 17px;
  font-weight: 400;
  padding: 0 2px;
}
.game-time-block {
  max-width: 60px;
  width: 100%;
}
.game-online {
  letter-spacing: 1px;
  font-size: 0.8rem;
}
.state-small {
  font-size: 0.7rem;
}
.period-block {
  line-height: 12px;
}
</style>
